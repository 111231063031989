// Copyright © 2022 Move Closer

import { Component, Prop, PropSync, Vue } from 'vue-property-decorator'
import { DashmixIconName } from '@movecloser/ui-core'

import { MosaicAddons } from '../Mosaic.contracts'
import { PickerCallback } from '../../../../contracts'
import { ExposesRelatedRecord, ResolvesRelatedAsync } from '../../../../services'

/**
 * AbstractAddon component that every MosaicAddon **SHOULD** extend
 *
 * @property _item - addon item's content
 *
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl> (edited)
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl> (original)
 */
@Component<AbstractAddon>({ name: 'AbstractAddon' })
export class AbstractAddon extends Vue {
  @PropSync('item', { type: Object, required: true })
  public _item!: MosaicAddons

  @Prop({ type: Function, required: false })
  public readonly pickRelated!: PickerCallback

  @Prop({ type: Object, required: true })
  public readonly relatedService!: ResolvesRelatedAsync & ExposesRelatedRecord

  public readonly DashmixIconName = DashmixIconName
}
