






























































































import { Component, PropSync } from 'vue-property-decorator'
import { IFile, ImageType } from '@movecloser/ui-core'

import { AbstractAddon } from '../AbstractAddon.form'

import { EmbedAddon } from './Embed.contracts'
import {
  LinkWithLabel,
  Picker,
  Related,
  RelatedType,
  UnresolvedLink
} from '../../../../../contracts'
import { AnyObject } from '@movecloser/front-core'
import { LinkForm } from '../../../../molecules'

@Component<EmbedAddonForm>({
  name: 'EmbedAddonForm',
  components: { LinkForm },
  mounted () {
    this.findImageRelated()
  }
})
export class EmbedAddonForm extends AbstractAddon {
  @PropSync('item', { type: Object, required: true })
  public _item!: EmbedAddon

  public onLinkUpdate (link: LinkWithLabel | UnresolvedLink): void {
    this._item = { ...this._item, link } as EmbedAddon
  }

  public readonly regex = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch?))\??v?=?([^#&?"]*).*/

  public get hasImage (): boolean {
    return !!this._item.thumbnail && typeof this._item.thumbnail.value !== 'undefined'
  }

  public get url (): string {
    if (!this._item || !this._item.videoId) {
      return ''
    } else {
      return `https://www.youtube.com/watch?v=${this._item.videoId}`
    }
  }

  public set url (url: string) {
    if (!url) {
      return
    }

    const youtubeMatch = url.match(this.regex)

    if (youtubeMatch) {
      this.onVideoIdChange(youtubeMatch[7])
    }
  }

  public onVideoIdChange (id: string): void {
    this._item = {
      ...this._item,
      videoId: id
    }
  }

  public onSelection (): void {
    this.findThumbnailSource()
  }

  public findThumbnailSource (): void {
    this.pickRelated(Picker.File, this.setThumbnailSource)
  }

  protected setThumbnailSource (source: Related<RelatedType.File>): void {
    this._item.thumbnail = source

    this.$nextTick(() => {
      this.findImageRelated()
    })
  }

  protected findImageRelated (): void {
    if (!this._item.thumbnail || typeof this._item.thumbnail.value === 'undefined' ||
      typeof this._item.thumbnail.type === 'undefined') {
      return
    }
    const related = this.relatedService.resolve(this._item.thumbnail, {})

    if (!(related instanceof Promise)) {
      this.getImageFromRelated(related)
      return
    }

    related.then((related: AnyObject) => {
      this.getImageFromRelated(related)
    }).catch(error => {
      console.debug(error)
    })
  }

  public imgTitle: string = ''

  public thumbnail: IFile | null = null

  protected getImageFromRelated (related: AnyObject): void {
    if (Object.prototype.hasOwnProperty.call(related, 'alt')) {
      this.imgTitle = related.title
    }

    if (Object.prototype.hasOwnProperty.call(related, 'url')) {
      this.thumbnail = {
        type: ImageType.JPG,
        thumbnail: related.url
      }

      if (Object.prototype.hasOwnProperty.call(related, 'mime')) {
        this.thumbnail.type = related.mime
      }
    }
  }

  public onRemove (): void {
    this.removeThumbnail()
  }

  public removeThumbnail (): void {
    this._item.thumbnail = {} as Related<RelatedType.File>
  }
}
export default EmbedAddonForm
